import React, { useState } from 'react';
import { ProxyFormProps } from '.';
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { addProxy } from '../../api/Proxy';
import { Proxy, ProxyMode } from '../../Interfaces/Proxy';
import { isDecimalNumber } from '../../helpers/string';
import { portAvailable } from '../../api/Device';

const ProxyForm: React.FC<ProxyFormProps> = () => {
    const { deviceId } = useParams<{ deviceId: string }>();
    const [name, setName] = useState('');
    const [port, setPort] = useState('');
    const [type, setType] = useState('');
    const [mode, setMode] = useState(ProxyMode.OPENDNS);

    const modes = [ProxyMode.OPENDNS];
    const types = ['http', 'ssh', 'rtsp'];

    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (deviceId) {
            if (!name || !port || !type) {
                toast.error('Please fill in all fields');
                return;
            }

            if (isDecimalNumber(port) === false) {
                toast.error('Please enter a valid port number');
                return;
            }

            const available = await portAvailable(deviceId, name, parseInt(port));
            if (!available) {
                toast.error('Port is already in use');
                return;
            }

            const localPort = parseInt(port)
            const proxy = { name, localPort, type, mode, deviceId } as Proxy;
            await addProxy(deviceId, proxy);
            setName('');
            setPort('');
            setMode(ProxyMode.OPENDNS);
            setType('');
            navigate(`/devices/${deviceId}`, { state: { toastMessage: 'Proxy added successfully' } });
        } else {
            toast.error('Failed to add proxy: invalid device ID');
        }
    };

    return (
        <div>
            <div className="flex items-start justify-center min-h-screen bg-gray-50" style={{ paddingTop: '5vh' }}>
                <div className="bg-white p-10 rounded-lg shadow-lg w-1/2">
                    <h2 className="text-3xl font-bold mb-6 text-center">New Proxy</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4 space-y-3">
                            <div>
                                <label htmlFor="name" className="block text-gray-700 text-lg font-bold mb-2">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                                    placeholder="Enter your name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="port" className="block text-gray-700 text-lg font-bold mb-2">Port</label>
                                <input
                                    type="text"
                                    id="port"
                                    name="port"
                                    className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                                    placeholder="Enter your local port"
                                    value={port}
                                    onChange={(e) => setPort(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="mode" className="block text-gray-700 text-lg font-bold mb-2">Mode</label>
                                <select
                                    id="mode"
                                    name="mode"
                                    className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                                    value={mode}
                                    onChange={(e) => setMode(e.target.value as ProxyMode)}
                                    style={{ background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="gray" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center`, backgroundSize: '18px 18px' }}
                                >
                                    <option value="" disabled>Select a mode</option>
                                    {modes.map((modeOption) => (
                                        <option key={modeOption} value={modeOption}>
                                            {modeOption}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="type" className="block text-gray-700 text-lg font-bold mb-2">Protocol</label>
                                <select
                                    id="type"
                                    name="type"
                                    className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    style={{ background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="gray" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center`, backgroundSize: '18px 18px' }}
                                >
                                    <option value="" disabled>Select a protocol</option>
                                    {types.map((typeOption) => (
                                        <option key={typeOption} value={typeOption}>
                                            {typeOption}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline text-lg w-full"
                            >
                                OK
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Toaster 
                position="top-right"
                toastOptions={{
                    duration: 2000,
                    style: {
                        width: '100%',
                        textAlign: 'center',
                    },
                }}
            />
        </div>
    );
};

export default ProxyForm;
