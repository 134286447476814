export const downloadFile = (content: string, filename: string, contentType: string = 'application/toml') => {
    const blob = new Blob([content], { type: contentType })
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.click()
    URL.revokeObjectURL(url)
}
  