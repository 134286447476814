export enum ProxyMode {
    OPENDNS = "OPENDNS",
    P2P = "P2P",
}

export interface Proxy {
    id?: string;
    name: string;
    customDomain?: string;
    localPort: number;
    type: string;
    mode: ProxyMode;
    deviceId?: string;
    localIp?: string;
    secretKey?: string;
}
