import React, { useState } from 'react';
import './Header.scss';
import nodeRTCIcon from '../../assets/images/icon.png';
import { HeaderProps } from '.';
import { useNavigate, Link } from 'react-router-dom';

const Header: React.FC<HeaderProps> = ({
    username, 
    setAccessToken,
    setRefreshToken,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const initials = username.split(' ').map(name => name[0]).join('').toUpperCase();
    const navigate = useNavigate();

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        localStorage.setItem('accessToken', '');
        localStorage.setItem('refreshToken', '');
        setAccessToken('');
        setRefreshToken('');
        navigate('/login');
    }

    return (
        <header className="header flex justify-between items-center p-4 bg-gray-50 max-h-20 border-b border-black">
            <div className="logo flex items-center">
                <Link to="/home">
                    <img src={nodeRTCIcon} alt="NodeRTC Icon" className="w-20 h-20 mr-4"/>
                </Link>
                <nav className="flex items-center space-x-4">
                    <Link to="/home" className="text-sm font-bold text-gray-700 hover:text-gray-900">Devices</Link>
                </nav>
            </div>
            <div className="accordion relative">
                <button 
                    className="w-9 h-9 rounded-full bg-black text-white flex items-center justify-center text-lg" 
                    onClick={toggleAccordion}>
                    {initials}
                </button>
                {isOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg">
                        {/* <button className="block w-full text-left px-4 py-2 hover:bg-gray-100" onClick={() => alert('Settings clicked')}>Settings</button> */}
                        <button className="block w-full text-left px-4 py-2 hover:bg-gray-100" onClick={handleLogout}>Logout</button>
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
