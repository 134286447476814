import { User } from '../Interfaces/User'
import Keycloak  from 'keycloak-js'

export const getUser = async (keycloak: Keycloak): Promise<User|undefined> => {
    try {
        const profile = await keycloak.loadUserProfile()
        if (profile) {
            const user: User = {
                id: profile.id as string,
                email: profile.username as string,
                firstName: profile.firstName as string,
                lastName: profile.lastName as string,
                organizationId: (profile.attributes?.organizationId as [string])[0],
            }
            return user;
        } else {
            throw new Error('Profile not found');
        }
    } catch (error) {
        return undefined;
    }
}
