import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DeviceEditorProps } from '.';
import { Proxy } from '../../Interfaces/Proxy';
import toast, { Toaster } from 'react-hot-toast';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { deleteProxy, getProxies } from '../../api/Proxy';
import { getDevice } from '../../api/Device';
import { Device } from '../../Interfaces/Device';

const DeviceEditor: React.FC<DeviceEditorProps> = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const [device, setDevice] = useState<Device | undefined>(undefined);
  const [proxies, setProxies] = useState<Proxy[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();

  useEffect(() => {
      if (location.state?.toastMessage) {
          toast.success(location.state.toastMessage);
      }
      location.state = undefined;
  }, [location]);  

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value);

  const handleDelete = async (proxyId: string | undefined) => {
    if (deviceId && proxyId) {
        try {
            await deleteProxy(proxyId);
            const proxies = await getProxies(deviceId);
            setProxies(proxies);
            toast.success('Device deleted successfully');
        } catch (error) {
            toast.error('Failed to delete device: API error');
        }
    } else {
        toast.error('Failed to delete device: invalid ID');
    }
  };

  useEffect(() => {
    const fetchProxies = async () => {
        if (!deviceId) return;
        setProxies(await getProxies(deviceId))
    };
    const fetchDevice = async () => {
        if (!deviceId) return;
        setDevice(await (getDevice(deviceId)))
    }
    fetchProxies();
    fetchDevice();
    }, []);

    const filteredDevices = proxies.filter(proxy =>
        proxy.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


   return (
    <div>
        <div className="p-6 bg-gray-50 min-h-screen">
            <div className="mb-4" style={{ marginBottom: '30px', marginTop: '15px' }}>
              <h1 className="text-2xl font-bold">{device?.name}</h1>
            </div>
            <div className="mb-4 flex items-center">
                <input
                    type="text"
                    placeholder="Search by proxy name"
                    className="p-2 border border-gray-300 rounded-md"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <Link to={`proxy/new`} className="ml-4 p-2 bg-blue-500 text-white rounded-md flex items-center hover:bg-blue-700">
                    <PlusIcon className="h-5 w-5 mr-2" /> New Proxy
                </Link>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                    <thead>
                        <tr>
                            <th className="px-6 py-3 border-b-2 border-r border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                Name
                            </th>
                            <th className="px-6 py-3 border-b-2 border-r border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                Domain
                            </th>
                            <th className="px-6 py-3 border-b-2 border-r border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                Mode
                            </th>
                            <th className="px-6 py-3 border-b-2 border-r border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                Protocol
                            </th>
                            <th className="px-6 py-3 border-b-2 border-r border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                Port
                            </th>
                            <th className="px-6 py-3 border-b-2 border-r border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                Delete
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDevices.map((proxy, index) => (
                            <tr key={index} className="relative">
                                <td className="px-6 py-4 border-b border-r border-gray-200 text-center">{proxy.name}</td>
                                <td className="px-6 py-4 border-b border-r border-gray-200 text-center">
                                    <Link to={`https://${proxy.customDomain}.tunnel.nodertc.live`} className="text-blue-500 hover:underline">
                                        {proxy.customDomain}
                                    </Link>
                                </td>
                                <td className="px-6 py-4 border-b border-r border-gray-200 text-center">{proxy.mode}</td>
                                <td className="px-6 py-4 border-b border-r border-gray-200 text-center">{proxy.type.toUpperCase()}</td>
                                <td className="px-6 py-4 border-b border-r border-gray-200 text-center">{proxy.localPort}</td>
                                <td className="px-6 py-4 border-b border-gray-200 text-center">
                                    <button onClick={() => handleDelete(proxy.id)} className="text-red-500 hover:text-red-700">
                                        <TrashIcon className="h-5 w-5" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        <Toaster
            position="top-right"
            toastOptions={{
                duration: 2000,
                style: {
                    width: '100%',
                    textAlign: 'center',
                },
            }}
        />
    </div>
    );
};

export default DeviceEditor
