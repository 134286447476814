import { Device } from "../Interfaces/Device";
import { apiURL } from "./Config";

export const getDevicesByOrganization = async (organizationId: string): Promise<Device[]> => {
    const response = await fetch(`${apiURL}/devices/organization/${organizationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
    if (!response.ok) {
        throw new Error('Failed to getDevices')
    }

    const data = await response.json();
    const devices: Device[] = data.map((d: any) => ({
        id: d.id,
        name: d.name,
        activationStatus: d.activationStatus,
        connectionStatus: d.connectionStatus,
        clientId: d.clientId,
        clientSecret: d.clientSecret,
    }));
    return devices;    
}

export const getDevice = async (deviceId: string): Promise<Device> => {
    const response = await fetch(`${apiURL}/devices/${deviceId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
    if (!response.ok) {
        throw new Error('Failed to getDevice')
    }

    const data = await response.json();
    const device: Device = {
        id: data.id,
        name: data.name,
        activationStatus: data.activationStatus,
        connectionStatus: data.connectionStatus,
        clientId: data.clientId,
        clientSecret: data.clientSecret,
    };
    return device;    
}

export const addDevice = async (organizationId: string, device: Device): Promise<Device> => {
    const { name } = device
    const response = await fetch(`${apiURL}/devices`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            organizationId,
            name,
        }),
    })
    console.log(JSON.stringify(response))
    return device;
}

export const deleteDevice = async (deviceId: string): Promise<void> => {
    const response = await fetch(`${apiURL}/devices/${deviceId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
    if (!response.ok) {
        throw new Error('Failed to deleteDevice')
    }
}

export const getConfiguration = async (deviceId: string): Promise<string> => {
    const response = await fetch(`${apiURL}/devices/${deviceId}/configuration`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
    if (!response.ok) {
        throw new Error('Failed to getConfiguration')
    }

    const data = await response.json();
    return data.configuration;
}

export const portAvailable = async (deviceId: string, name: string, port: number): Promise<boolean> => {
    const response = await fetch(`${apiURL}/devices/${deviceId}/available?port=${port}&name=${name}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
    if (!response.ok) {
        throw new Error('Failed to portAvailable')
    }

    const data = await response.json();
    return data.available;
}