import React, { useState } from 'react';
import { DeviceFormProps } from '.';
import { addDevice } from '../../api/Device';
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const DeviceForm: React.FC<DeviceFormProps> = ({ user }) => {
    const [name, setName] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!name) {
            toast.error('Please fill in all fields');
            return;
        }
        const device = { name };
        await addDevice(user.organizationId, device);
        setName('');
        navigate('/home', { state: { toastMessage: 'Device added successfully' } });
    };

    return (
        <div>
            <div className="flex items-start justify-center min-h-screen bg-gray-50" style={{ paddingTop: '5vh' }}>
                <div className="bg-white p-10 rounded-lg shadow-lg w-1/2">
                    <h2 className="text-3xl font-bold mb-6 text-center">New Device</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-gray-700 text-lg font-bold mb-2">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                                placeholder="Enter your name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="flex items-center justify-center">
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline text-lg w-full"
                            >
                                OK
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Toaster 
                    position="top-right"
                    toastOptions={{
                        duration: 2000,
                        style: {
                            width: '100%',
                            textAlign: 'center',
                        },
                    }}
                />
        </div>
    );
};

export default DeviceForm;
