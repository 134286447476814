import React, { useEffect, useState } from 'react';
import { DashboardProps } from '.';
import { Link } from 'react-router-dom';
import { PlusIcon, ArrowDownTrayIcon, TrashIcon } from '@heroicons/react/24/solid';
import { deleteDevice, getConfiguration, getDevicesByOrganization } from '../../api/Device';
import toast, { Toaster } from 'react-hot-toast';
import { downloadFile } from '../../helpers/download';
import { Device } from '../../Interfaces/Device';
import { getProxies } from '../../api/Proxy';
import { getOrganizationInfo } from '../../api/Organization';

const Dashboard: React.FC<DashboardProps> = ({ devices, setDevices, organizationId }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [organizationName, setOrganizationName] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleDownload = async (device: Device | undefined) => {
        if (device) {
            if (device.id !== undefined && device.clientId !== undefined) {
                const proxies = await getProxies(device.id)
                if (proxies.length === 0) {
                    toast.error('Failed to download configuration: no proxies found. Please add at least one proxy to the device.');
                    return;
                }
                const configuration = await getConfiguration(device.id)
                downloadFile(configuration, `${device.clientId}.toml`, 'application/toml')
            } else {
                toast.error('Failed to download configuration: device ID or client ID is undefined');
            }
        } else {
            toast.error('Failed to download configuration: invalid device');
        }
    };

    const handleDelete = async (deviceId: string | undefined) => {
        if (deviceId) {
            try {
                await deleteDevice(deviceId);
                const devices = await getDevicesByOrganization(organizationId);
                setDevices(devices);
                toast.success('Device deleted successfully');
            } catch (error) {
                toast.error('Failed to delete device: API error');
            }
        } else {
            toast.error('Failed to delete device: invalid ID');
        }
    };

    const filteredDevices = devices.filter(device =>
        device.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        const fetchOrganizationInfo = async () => {
            try {
                const organization = await getOrganizationInfo(organizationId);
                setOrganizationName(organization.name);
            } catch (error) {
                console.error('Failed to fetch organization', error);
            }
        };
        fetchOrganizationInfo();
    }, []);

    return (
        <div>
            <div className="p-6 bg-gray-50 min-h-screen">
                <div className="mb-4" style={{ marginBottom: '30px' }}>
                    <h1 className="text-2xl font-bold">{organizationName}</h1>
                </div>
                <div className="mb-4 flex items-center">
                    <input
                        type="text"
                        placeholder="Search by device name"
                        className="p-2 border border-gray-300 rounded-md"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <Link to="/device/new" className="ml-4 p-2 bg-blue-500 text-white rounded-md flex items-center hover:bg-blue-700">
                        <PlusIcon className="h-5 w-5 mr-2" /> New Device
                    </Link>
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead>
                            <tr>
                                <th className="px-6 py-3 border-b-2 border-r border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    Device Name
                                </th>
                                <th className="px-6 py-3 border-b-2 border-r border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    Client ID
                                </th>
                                <th className="px-6 py-3 border-b-2 border-r border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    Connection Status
                                </th>
                                <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredDevices.map((device, index) => (
                                <tr key={index} className="relative">
                                    <td className="px-6 py-4 border-b border-r border-gray-200 text-center">
                                        <Link to={`/devices/${device.id}`} className="text-blue-500 hover:underline">
                                            {device.name}
                                        </Link>
                                    </td>
                                    <td className="px-6 py-4 border-b border-r border-gray-200 text-center">{device.clientId}</td>
                                    <td className="px-6 py-4 border-b border-r border-gray-200 text-center">{device.connectionStatus}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-center">
                                        <div className="relative inline-block">
                                            <button
                                                className="mx-2 p-2 bg-green-500 text-white rounded-md hover:bg-green-700"
                                                onClick={() => handleDownload(device)}
                                                onMouseEnter={() => {
                                                    document.getElementById(`tooltip-download-${device.id}`)?.classList.remove('hidden');
                                                }}
                                                onMouseLeave={() => {
                                                    document.getElementById(`tooltip-download-${device.id}`)?.classList.add('hidden');
                                                }}
                                            >
                                                <ArrowDownTrayIcon className="h-5 w-5" />
                                            </button>
                                            <div
                                                id={`tooltip-download-${device.id}`}
                                                className="hidden absolute z-50 whitespace-normal break-words rounded-lg bg-black py-1.5 px-3 font-sans text-sm font-normal text-white bottom-full mb-2 left-1/2 transform -translate-x-1/2"
                                            >
                                                Download
                                            </div>
                                        </div>
                                        <div className="relative inline-block">
                                            <button
                                                className="mx-2 p-2 bg-red-500 text-white rounded-md hover:bg-red-700"
                                                onClick={() => handleDelete(device.id)}
                                                onMouseEnter={() => {
                                                    document.getElementById(`tooltip-delete-${device.id}`)?.classList.remove('hidden');
                                                }}
                                                onMouseLeave={() => {
                                                    document.getElementById(`tooltip-delete-${device.id}`)?.classList.add('hidden');
                                                }}
                                            >
                                                <TrashIcon className="h-5 w-5" />
                                            </button>
                                            <div
                                                id={`tooltip-delete-${device.id}`}
                                                className="hidden absolute z-50 whitespace-normal break-words rounded-lg bg-black py-1.5 px-3 font-sans text-sm font-normal text-white bottom-full mb-2 left-1/2 transform -translate-x-1/2"
                                            >
                                                Delete
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Toaster 
                position="top-right"
                toastOptions={{
                    duration: 2000,
                    style: {
                        width: '100%',
                        textAlign: 'center',
                    },
                }}
            />
        </div>
    );
};

export default Dashboard;
