import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import DeviceForm from './containers/DeviceForm/DeviceForm';
import PageNotFound from './pages/NotFound/NotFound';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './auth/Keycloak';
import PrivateRoute from './containers/PrivateRoute/PrivateRoute';
import MainLayout from './containers/MainLayout/MainLayout';
import { User } from './Interfaces/User';
import { getUser } from './api/User';
import DeviceEditor from './containers/DeviceEditor/DeviceEditor';
import ProxyForm from './containers/ProxyForm.tsx/ProxyForm';

const App: React.FC = () => {
  const [user, setUser] = useState<User|undefined>(undefined)
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'))
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'))

  useEffect(() => {
      const fetchUser = async () => {
          setAccessToken(localStorage.getItem('accessToken'))
          setRefreshToken(localStorage.getItem('refreshToken'))
          if (accessToken && refreshToken) {
              keycloak.token = accessToken
              keycloak.refreshToken = refreshToken    
              const user = await getUser(keycloak)
              if (user) {
                  setUser(user);
              } else {
                  localStorage.setItem('accessToken','')
                  localStorage.setItem('refreshToken', '')
                  setAccessToken('')
                  setRefreshToken('')
              }
          }
      }
      fetchUser()
    }, []);

  return (
    <div className="App">
      <ReactKeycloakProvider authClient={keycloak}>
        <BrowserRouter>
          <Routes>
            <Route path="/static" element={<Navigate to="/login" />} />
            <Route path="/login" element={
              <Login 
                accessToken={accessToken} 
                refreshToken={refreshToken}
                setAccessToken={setAccessToken}
                setRefreshToken={setRefreshToken}
                setUser={setUser}
              />
            } />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route 
              path="/home" 
              element={
                <PrivateRoute 
                  accessToken={accessToken} 
                  refreshToken={refreshToken}
                  setAccessToken={setAccessToken}
                  setRefreshToken={setRefreshToken}
                >
                  {user && (
                    <MainLayout 
                      user={user}
                      setAccessToken={setAccessToken}
                      setRefreshToken={setRefreshToken}
                    >
                      <Home user={user} />
                    </MainLayout>
                  )}
                </PrivateRoute>
              } 
            />
            <Route 
              path="/device/new" 
              element={
                <PrivateRoute 
                  accessToken={accessToken} 
                  refreshToken={refreshToken}
                  setAccessToken={setAccessToken}
                  setRefreshToken={setRefreshToken}
                >
                  {user && (
                    <MainLayout 
                      user={user}
                      setAccessToken={setAccessToken}
                      setRefreshToken={setRefreshToken}
                    >
                      <DeviceForm user={user} />
                    </MainLayout>
                  )}
                </PrivateRoute>
              }
            />
            <Route 
              path="/devices/:deviceId" 
              element={
                <PrivateRoute 
                  accessToken={accessToken} 
                  refreshToken={refreshToken}
                  setAccessToken={setAccessToken}
                  setRefreshToken={setRefreshToken}
                >
                  {user && (
                    <MainLayout 
                      user={user}
                      setAccessToken={setAccessToken}
                      setRefreshToken={setRefreshToken}
                    >
                      <DeviceEditor />
                    </MainLayout>
                  )}
                </PrivateRoute>
              }
            />
                        <Route 
              path="/devices/:deviceId/proxy/new" 
              element={
                <PrivateRoute 
                  accessToken={accessToken} 
                  refreshToken={refreshToken}
                  setAccessToken={setAccessToken}
                  setRefreshToken={setRefreshToken}
                >
                  {user && (
                    <MainLayout 
                      user={user}
                      setAccessToken={setAccessToken}
                      setRefreshToken={setRefreshToken}
                    >
                      <ProxyForm />
                    </MainLayout>
                  )}
                </PrivateRoute>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </ReactKeycloakProvider>
    </div>
  );
}

export default App;
