import React, { useEffect, useState } from 'react'
import './Login.scss'
import nodeRTCIcon from '../../assets/images/icon.png'
import { useNavigate } from 'react-router-dom'
import keycloak from '../../auth/Keycloak'
import toast, { Toaster } from 'react-hot-toast'
import { LoginProps } from '.'
import { getUser } from '../../api/User'


const Login: React.FC<LoginProps> = ({
    accessToken,
    refreshToken,
    setAccessToken,
    setRefreshToken,
    setUser,
}) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        if (accessToken && refreshToken) {
            keycloak.token = accessToken
            keycloak.refreshToken = refreshToken
            navigate('/home')
        }
    }, [navigate])

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault()

        try {
            const body = new URLSearchParams()
            body.append('grant_type', 'password')
            body.append('client_id', keycloak.clientId || '')
            body.append('username', email)
            body.append('password', password)

            const response = await fetch(`${keycloak.authServerUrl}/realms/${keycloak.realm}/protocol/openid-connect/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body.toString(),
            })

            if (!response.ok) {
                toast.error('Password or email is incorrect.');
                throw new Error('Failed to login')
            }

            const data = await response.json()
            keycloak.token = data.access_token
            keycloak.refreshToken = data.refresh_token

            localStorage.setItem('accessToken', data.access_token)
            localStorage.setItem('refreshToken', data.refresh_token)
            setAccessToken(data.access_token)
            setRefreshToken(data.refresh_token)
            const user = await getUser(keycloak)
            if (user)
                setUser(user)

            navigate('/home')
        } catch (error) {
            console.error('Login failed', error)
        }
    }

    return (
        <div className='login-container'>
            <Toaster 
                position='top-right'
                toastOptions={{
                    duration: 2000,
                    style: {
                        width: '100%',
                        textAlign: 'center',
                    },
                }}
            />
            <div className='login-image'></div>
            <div className='login-form-container'>
                <form className='login-form' onSubmit={handleLogin}>
                    <div className='icon-wrapper'>
                        <img src={nodeRTCIcon} alt='NodeRTC Icon' className='nodertc-icon' />
                    </div>
                    <div className='form-field'>
                        <input
                            type='email'
                            id='username'
                            name='username'
                            className='form-input'
                            placeholder='Enter your email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className='form-field'>
                        <input
                            type='password'
                            id='password'
                            name='password'
                            className='form-input'
                            placeholder='Enter your password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type='submit' className='submit-button'>
                        LOGIN
                    </button>
                    <div className='form-links'>
                        <span className='link register-now'>Register now</span>
                        <span className='link forgot-password'>Forgot my password?</span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login
  