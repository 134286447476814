import Header from "../Header/Header";
import { MainLayoutProps } from ".";

const MainLayout: React.FC<MainLayoutProps> = ({
    children,
    user,
    setAccessToken,
    setRefreshToken,
}) => {
    return (
        <>
        { user && (
            <>
                <Header 
                    username={`${user.firstName} ${user.lastName}`}
                    setAccessToken={setAccessToken}
                    setRefreshToken={setRefreshToken}
                />
                <div className="main-content">
                    {children}
                </div>
            </>
        )}
        </>
    );
}

export default MainLayout;