import { useEffect, useState } from "react";
import { Device } from "../../Interfaces/Device";
import { getDevicesByOrganization } from "../../api/Device";
import Dashboard from "../../containers/Dashboard/Dashboard";
import { HomeProps } from "./index";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";

const Home: React.FC<HomeProps> = ({ user }) => {
    const [devices, setDevices] = useState<Device[]>([]);
    const location = useLocation();

    useEffect(() => {
        if (location.state?.toastMessage) {
            toast.success(location.state.toastMessage);
        }
        location.state = undefined;
    }, [location]);

    useEffect(() => {
        const fetchDevices = async () => {
            try {
                const devices = await getDevicesByOrganization(user.organizationId);
                setDevices(devices);
            } catch (error) {
                console.error('Failed to fetch devices', error);
                toast.error('Failed to fetch devices');
            }
        };
        fetchDevices();
    }, [user.organizationId]);

    return (
        <div className="p-6 bg-gray-50 min-h-screen">
            <Toaster 
                position="top-right"
                toastOptions={{
                    duration: 2000,
                    style: {
                        width: '100%',
                        textAlign: 'center',
                    },
                }}
            />
            <Dashboard devices={devices} setDevices={setDevices} organizationId={user.organizationId} />
        </div>
    );
};

export default Home;
