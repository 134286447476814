import { Organization } from "../Interfaces/Organization";
import { apiURL } from "./Config";

export const getOrganizationInfo = async (organizationId: string): Promise<Organization> => {
    const response = await fetch(`${apiURL}/organizations/${organizationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
    if (!response.ok) {
        throw new Error('Failed to getOrganization')
    }

    const data = await response.json();
    const organization: Organization = {
        id: data.id,
        name: data.name,
    };
    return organization;    
}
