import { Proxy } from "../Interfaces/Proxy";
import { apiURL } from "./Config";

export const getProxies = async (deviceId: string): Promise<Proxy[]> => {
    const response = await fetch(`${apiURL}/proxies/${deviceId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
    if (!response.ok) {
        throw new Error('Failed to getProxies')
    }

    const data = await response.json();
    const proxies: Proxy[] = data.map((d: any) => ({
        id: d.id,
        name: d.name,
        customDomain: d.customDomain,
        type: d.type,
        mode: d.mode,
        secretKey: d.secretKey,
        localIp: d.localIp,
        localPort: d.localPort,
        deviceId: d.deviceId,
    }));
    return proxies;    
}

export const addProxy = async (deviceId: string, proxy: Proxy): Promise<Proxy> => {
    const { name, localPort, type } = proxy
    const response = await fetch(`${apiURL}/proxies`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name,
            type,
            localPort,
            deviceId,
        }),
    })
    console.log(JSON.stringify(response))
    return proxy;
}

export const deleteProxy = async (proxyId: string): Promise<void> => {
    const response = await fetch(`${apiURL}/proxies/${proxyId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
    if (!response.ok) {
        throw new Error('Failed to deleteProxy')
    }
}
