import { useKeycloak } from '@react-keycloak/web'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PrivateRouteProps from '.'

const PrivateRoute: React.FC<PrivateRouteProps> = ({ 
    children,
    accessToken,
    refreshToken,
    setAccessToken,
    setRefreshToken,
}) => {
    const { keycloak } = useKeycloak()
    const [isProfileLoaded, setIsProfileLoaded] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const loadProfile = async () => {
            try {
                if (!keycloak.profile) {
                    await keycloak.loadUserProfile()
                }
                setIsProfileLoaded(true)
            } catch (error) {
                console.error('Failed to load profile', error)
                localStorage.setItem('accessToken', '')
                localStorage.setItem('refreshToken', '')
                setAccessToken('')
                setRefreshToken('')
                navigate('/login')
            }
        }

        const loadTokensFromLocalStorage = () => {
            if (accessToken && refreshToken) {
                keycloak.token = accessToken
                keycloak.refreshToken = refreshToken
                setIsProfileLoaded(true)
            } else {
                navigate('/login')
            }
        }

        if (keycloak.authenticated) {
            loadProfile()
        } else {
            loadTokensFromLocalStorage()
        }
    }, [keycloak, navigate, accessToken, refreshToken])

    if (!isProfileLoaded || !children) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p>Loading...</p>
            </div>  
        )
    }

    return <>{children}</>
}

export default PrivateRoute
