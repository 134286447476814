import Keycloak, { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL || 'https://auth.nodertc.live',
  realm: process.env.REACT_APP_KEYCLOAK_REALM || 'nodertc',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'nodertc-app',
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
